/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A transfer partner for a loyalty program
 * @export
 * @interface TransferPartner
 */
export interface TransferPartner {
    /**
     * Name of the transfer partner
     * @type {string}
     * @memberof TransferPartner
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TransferPartner
     */
    transferRatio: number;
}

/**
 * Check if a given object implements the TransferPartner interface.
 */
export function instanceOfTransferPartner(value: object): value is TransferPartner {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('transferRatio' in value) || value['transferRatio'] === undefined) return false;
    return true;
}

export function TransferPartnerFromJSON(json: any): TransferPartner {
    return TransferPartnerFromJSONTyped(json, false);
}

export function TransferPartnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferPartner {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'transferRatio': json['transfer_ratio'],
    };
}

export function TransferPartnerToJSON(value?: TransferPartner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'transfer_ratio': value['transferRatio'],
    };
}

